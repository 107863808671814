import React from 'react'
import './education.css';


import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Education() {

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div className='AboutMainDiv' id="Education">
      <Grid container style={{ height: "90vh" }} alignItems="stretch">
        <Grid item xs={4} sm={12} md={6} className="gridElement">
          <Typography variant='h3'>Education</Typography>
        </Grid>
        <Grid item xs={8} sm={12} md={6} className="gridElement">
          <div style={{ display: "flex", flexDirection: "column", padding: '1rem' }}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Bachelor of Engineering(CSE)
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>2017 - 2021</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <ul style={{marginLeft:"1rem"}}>
                <li>
                  <Typography>
                  I am a proud alumnus of KPR Institute of Engineering and Technology, where I pursued my Bachelor of Engineering with a CGPA of 7.99.
                  </Typography>
                </li>
                <li>
                  <Typography>
                  During my time at college, I was highly motivated to learn and focused on gaining practical knowledge of the subjects. 
                  </Typography>
                </li>
                <li>
                  <Typography>
                  I made it my daily aim to learn something new, and my commitment to excellence drove me to achieve my academic goals.
                  </Typography>
                </li>
                <li>
                  <Typography>
                  I was fortunate to have the guidance of exceptional mentors who provided me with valuable exposure to the concepts of computer science.
                  Their guidance and support enabled me to deepen my understanding of the subject matter and hone my skills in various areas, including programming, software development, and systems architecture.
                  </Typography>
                </li>
                <li>
                  <Typography>
                  
                  Overall, my college experience has played a pivotal role in shaping my career as a developer.
                  </Typography>
                </li>
              </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}  style={{ marginTop: "1rem" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Higher Secondary School
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>2016 - 2017</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <ul style={{marginLeft:"1rem"}}>
                <li>
                  <Typography>
                  During my higher secondary studies, I pursued the computer science group and secured 83%. 
                  </Typography>
                </li>
                <li>
                  <Typography>
                  It was during this time that my passion for computer science grew stronger. 
                  </Typography>
                </li>
                <li>
                  <Typography>
                  My computer science teacher noticed my exceptional logical thinking ability and encouraged me to explore programming beyond the syllabus.
                   His support and guidance inspired me to involve deeper into the subject and fueled my interest in pursuing a career in the field.
                  </Typography>
                </li>
              </ul>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
    </div>

  )
}
