import React from 'react'
import './contact.css';

import { Grid, Link, Typography } from '@mui/material'


import LinkedIconPng from '../../assets/linkedIn.png';
import GithubIconPng from '../../assets/github.png';
import MailIconPng from '../../assets/gmail.png'
import InstagramPng from '../../assets/instagram.png';

export default function Contact() {
  return (
    <div className='AboutMainDiv' id="Contact">
      <Grid container style={{ height: "90vh" }} alignItems="stretch">
        <Grid item xs={4} sm={12} md={6} className="gridElement">
          <Typography variant='h3'>Contact</Typography>
        </Grid>
        <Grid item xs={8} sm={12} md={6} className="gridElement">
          <div style={{ display: "flex", alignItems: "center", padding: '1rem' }} >
              <div style={{display:"flex",flexDirection:"column"}}>
                <div>
                <img src={LinkedIconPng} width={50} height={50} alt="LinkedIn Logo" />
                </div>
                <div style={{marginTop:"1rem"}}>
                <img src={MailIconPng} width={50} height={50} alt="Gmail Logo" />
                </div>
                <div style={{marginTop:"1rem"}}>
                <img src={GithubIconPng} width={50} height={50}  alt="Github Logo"/>
                </div>
                <div style={{marginTop:"1rem"}}>
                <img src={InstagramPng} width={50} height={50}  alt="Instagram Logo"/>
                </div>
              </div>
              <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",height:"14rem",marginLeft:"2rem"}}>
                  <div>
                  <Link underline="hover" color="inherit" href="https://www.linkedin.com/in/praveenkumarganesan/">
                  Praveenkumar Ganesan
                </Link>
                  </div>
                  <div  style={{marginTop:"1rem"}}>
                  <Link underline="hover" color="inherit" href="https://www.linkedin.com/in/praveenkumarganesan/">
                  praveenkumarlatha11@gmail.com
                </Link>
                  </div>
                  <div style={{marginTop:"1rem"}}>
                  <Link underline="hover" color="inherit" href="https://github.com/praveenkumar1610?tab=repositories">
                  praveenkumar1610
                </Link>
                  </div>
                  <div style={{marginTop:"1rem"}}>
                  <Link underline="hover" color="inherit" href="https://www.instagram.com/magical_smile_warrier/">
                  magical_smile_warrier
                </Link>
                  </div>
              </div>
          </div>
        </Grid>
      </Grid>
    </div>

  )
}
