import React from "react";
import "./skills.css";


import { Grid, Typography, Chip } from "@mui/material";


export default function Skills() {
  return (
    <div className="AboutMainDiv" id="Skills">
      <Grid container style={{ height: "90vh" }} alignItems="stretch">
        <Grid item xs={4} sm={12} md={6} className="gridElement">
          <Typography variant="h3">Tech Stack</Typography>
        </Grid>
        <Grid item xs={8} sm={12} md={6} className="gridElement">
          <div sx={{marginRight:"2rem"}}>
            <Chip label="React" variant="outlined" sx={{ color: "#fff",margin:"1rem",backgroundColor:"#1976d2" }} />
            <Chip
              label="Javascript"
              variant="outlined"
              sx={{ color: "#fff" ,margin:"1rem",backgroundColor:"#1976d2" }}
            />
            <Chip label="Python" variant="outlined" sx={{ color: "#fff" ,margin:"1rem",backgroundColor:"#1976d2" }} />
            <Chip label="Node" variant="outlined" sx={{ color: "#fff",margin:"1rem" ,backgroundColor:"#1976d2" ,backgroundColor:"#1976d2" }} />
            <Chip label="MongoDB" variant="outlined" sx={{ color: "#fff",margin:"1rem",backgroundColor:"#1976d2"  }} />
            <Chip label="Express" variant="outlined" sx={{ color: "#fff",margin:"1rem" ,backgroundColor:"#1976d2" }} />
            <Chip label="Django" variant="outlined" sx={{ color: "#fff",margin:"1rem",backgroundColor:"#1976d2"  }} />
            <Chip
              label="Flask"
              variant="outlined"
              sx={{ color: "#fff" ,margin:"1rem",backgroundColor:"#1976d2" }}
            />
            <Chip label="docker" variant="outlined" sx={{ color: "#fff",margin:"1rem" ,backgroundColor:"#1976d2" }} />
            <Chip label="Jenkins" variant="outlined" sx={{ color: "#fff",margin:"1rem" ,backgroundColor:"#1976d2" }} />
            <Chip label="CI/CD" variant="outlined" sx={{ color: "#fff",margin:"1rem" ,backgroundColor:"#1976d2"}} />
            <Chip label="SQL" variant="outlined" sx={{ color: "#fff",margin:"1rem" ,backgroundColor:"#1976d2" }} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
