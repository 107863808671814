import React from "react";
import "./about.css";

import { Grid, Typography } from "@mui/material";

export default function About() {



  return (
    <div className="AboutMainDiv" id="About">
      <Grid container style={{ height: "90vh" }} alignItems="stretch">
        <Grid item xs={6} sm={12} md={6} className="gridElement">
          <Typography variant="h3">About</Typography>
        </Grid>
        <Grid item xs={6} sm={12} md={6} className="gridElement">
          <div style={{ padding: '1rem' }}>
            <Typography variant="h6">
              I am glad to introduce myself, My name is Praveenkumar Ganesan, and I am a Full Stack Developer with a passion for learning and collaboration.I have extensive experience in developing and maintaining web applications, and I am constantly seeking to improve my skills and knowledge. As a team player, I value clear and open communication, and I strive to build strong relationships with my colleagues.
            </Typography>
            <Typography variant="h6">
            One of my key strengths is my ability to approach complex problems with the Divide and Conquer algorithm, achieving efficient and effective solutions. I am dedicated to delivering quality work that exceeds expectations and am always looking for ways to grow and improve.
            In addition to my technical expertise, I enjoy sharing my knowledge and mentoring others. I believe that by working together, we can achieve greater success and create a positive impact on our projects and clients.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
