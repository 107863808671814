
import './App.css';

import React  from 'react';
import { HashRouter as Router } from 'react-router-dom';


import Home from './Components/Home/Home';
import About from './Components/About/About';
import Skills from './Components/Skills/Skills';
import Experience from './Components/Experience/Experience';
import Education from './Components/Education/Education';
import Contact from './Components/Contact/Contact';


import Navbar from './Components/common/Navbar/Navbar';

function App() {


  return (
    <Router hashType="noslash">
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <section id="Home">
            <Home />
          </section>
          <section id="About">
            <About />
          </section>
          <section id="Experience">
            <Experience />
          </section>
          <section id="Skills">
            <Skills />
          </section>
          <section id="Education">
            <Education />
          </section>
          <section id="Contact">
            <Contact />
          </section>
        </div>
      </div>

    </Router>
  );
}

export default App;
