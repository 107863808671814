import React from 'react'
import './Home.css';

import { Grid, Typography } from '@mui/material';

import welcomeImg from '../../welcome.png';

export default function Home() {
  return (
    <div className='HomeMainDiv' id="Home">
      <Grid container style={{ height: "90vh" }} alignItems="stretch">
        <Grid item xs={6} sm={12} md={6} className="gridElement">
          <img src={welcomeImg} />
        </Grid>
        <Grid item xs={6} sm={12} md={6} className="gridElement">
          <div style={{padding:"5%"}}>
            <div>
              <Typography variant='h3' >Hii, I am Praveenkumar Ganesan</Typography>
            </div>
            <div style={{marginTop:20}}>
              <Typography variant='h5'>Full Stack Developer | Practitioner on Devops</Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>

  )
}
