import React from 'react'
import './experience.css';

import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function Experience() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='AboutMainDiv' id="Experience">
      <Grid container style={{ height: "90vh" }} alignItems="stretch">
        <Grid item xs={4} sm={12} md={6} className="gridElement">
          <Typography variant='h3'>Experience</Typography>
        </Grid>
        <Grid item xs={8} sm={12} md={6} className="gridElement">
          <div style={{ display: "flex", flexDirection: "column", padding: '1rem' }}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Rapid Acceleration
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>June 21 - Present Now</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ marginLeft: "1rem" }}>
                  <li>
                    <Typography>
                      I am excited to embark on my journey as a Full Stack Developer, having been offered a full-time opportunity after two years of impressive performance at my current company.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      In my role, I am responsible for developing user-friendly and responsive web applications, writing clean and efficient code, optimizing web applications, and staying up-to-date with emerging technologies.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      As a developer in the Express and MongoDB stack, I am also responsible for developing server-side web applications, integrating with databases, writing efficient and scalable code, and maintaining the backend infrastructure.

                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      I am dedicated to optimizing web applications for maximum speed and scalability, identifying and addressing performance issues and bugs in both frontend and backend components, and ensuring the technical feasibility of UI/UX designs.

                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      In addition, I conduct UI/UX testing to ensure a seamless user experience, configure and manage cloud infrastructure to ensure smooth deployment and operation of web applications, troubleshoot issues related to deployment and infrastructure, and participate in on-call rotations to respond to production issues.

                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      I also automate deployment and scaling processes to improve efficiency and reduce errors, and monitor and analyze system performance to identify and address issues related to deployment and infrastructure. I am committed to delivering high-quality and efficient solutions that exceed expectations, and I am constantly seeking opportunities for professional growth and development.
                    </Typography>
                  </li>

                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ marginTop: "2rem" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Rapid Acceleration(Intern)
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>March 21 - June 21</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ marginLeft: "1rem" }}>
                  <li>
                    <Typography>
                      I am thrilled to announce the next stage of my career growth, where I had the opportunity to work on both frontend and backend development.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      This experience has provided me with a holistic understanding of the development process and helped me gain expertise in the backend integration with the database.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      As a part of a product-based company, I gained hands-on experience in the end-to-end product development process.
                      This opportunity has helped me expand my skill set and marked the beginning of my journey towards becoming a Full Stack Developer.
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ marginTop: "2rem" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Guvi Geek Network(Intern)
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>March 21 - June 21</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ marginLeft: "1rem" }}>
                  <li>
                    <Typography>
                      I am excited to share my experience with industrial exposure in the IT industry.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      During my time, I had the opportunity to gain expertise in the React framework and improve my skills in collaborative coding and enhancing code quality.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      In addition to learning the basics such as variable naming conventions and efficient debugging methods, I had hands-on experience working with third-party libraries such as Material UI and Bootstrap CSS.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      These experiences have greatly expanded my knowledge and understanding of the full development process.
                      As a fresher, this opportunity marked the beginning of my journey into the IT world.
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </div>

        </Grid>
      </Grid>
    </div>

  )
}
